import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Alert, Row, Col, Spinner } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import api from '../api';
import { useAuth } from '../AuthContext'; // Import AuthContext for user info

const ReservationModal = ({ ad, show, handleClose, onReservationSuccess }) => {
  const { authState } = useAuth(); // Koristi authState za informacije o korisniku
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [totalPrice, setTotalPrice] = useState(0);
  const [availabilityStatus, setAvailabilityStatus] = useState(null);
  const [reservationStatus, setReservationStatus] = useState(null);
  const [csrfToken, setCsrfToken] = useState('');
  const [loading, setLoading] = useState(false); // Dodano stanje za loader
  const [userEmail, setUserEmail] = useState(authState?.user?.email || ''); // Provjera postoji li email

  // Dohvati korisničke podatke ako email nije prisutan u authState
  useEffect(() => {
    if (!userEmail) {
      const token = localStorage.getItem('token');
      if (token) {
        api.get('/users/profile', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setUserEmail(response.data.email); // Postavi korisnički email
        })
        .catch((error) => {
          console.error('Error fetching user profile:', error);
        });
      }
    }
  }, [authState, userEmail]);
  
  useEffect(() => {
    const fetchCsrfToken = async () => {
      try {
        const response = await api.get('/csrf-token', { withCredentials: true });
        setCsrfToken(response.data.csrfToken);
      } catch (error) {
        console.error('Error fetching CSRF token:', error);
      }
    };

    fetchCsrfToken();
  }, []);

  const calculateTotalPrice = (start, end) => {
    if (start && end) {
      const days = (end - start) / (1000 * 60 * 60 * 24); // Razlika u danima
      return days * ad.price;
    }
    return 0;
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
    const price = calculateTotalPrice(date, endDate);
    setTotalPrice(price);
    setAvailabilityStatus(null);
    setReservationStatus(null);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    const price = calculateTotalPrice(startDate, date);
    setTotalPrice(price);
    setAvailabilityStatus(null);
    setReservationStatus(null);
  };

  const formatDate = (date) => {
    return date.toLocaleDateString('en-GB'); // Formatiranje datuma u dd/mm/yyyy
  };

  const checkAvailability = async () => {
    try {
      const response = await api.post('/reservations/check-availability', {
        adId: ad.id,
        startDate: startDate.toISOString().split('T')[0],
        endDate: endDate.toISOString().split('T')[0],
      }, {
        headers: {
          'X-CSRF-Token': csrfToken,
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        withCredentials: true,
      });

      if (response.data.isAvailable) {
        setAvailabilityStatus('Oglas je dostupan za tražene datume.');
      } else {
        setAvailabilityStatus('Oglas nije dostupan za tražene datume.');
      }
    } catch (error) {
      console.error('Error checking availability:', error);
      setAvailabilityStatus('Došlo je do greške prilikom provjere dostupnosti. Pokušajte ponovo.');
    }
  };

  const handleReservation = async () => {
    setLoading(true); // Postavljanje loadera na true

    if (!authState || !authState.user) {
      console.error('Korisnik nije prijavljen ili authState.user ne postoji');
      setReservationStatus('Došlo je do greške. Korisnik nije prijavljen.');
      setLoading(false);
      return;
    }

    if (!userEmail) {
      console.error('Korisnički email nije dostupan');
      setReservationStatus('Korisnički email nije dostupan.');
      setLoading(false);
      return;
    }

    try {
      const token = localStorage.getItem('token');
      await api.post('/reservations', {
        adId: ad.id,
        startDate: startDate.toISOString().split('T')[0],
        endDate: endDate.toISOString().split('T')[0],
        totalPrice
      }, {
        headers: {
          'X-CSRF-Token': csrfToken,
          'Authorization': `Bearer ${token}`,
        },
        withCredentials: true,
      });

      // Slanje emaila korisniku kao potvrdu rezervacije
      await api.post('/reservations/send-confirmation-email', {
        userEmail: userEmail, // Provjeren email korisnika
        userName: authState.user.username, // Korisničko ime iz authState
        adTitle: ad.title,
        startDate: formatDate(startDate),
        endDate: formatDate(endDate),
        totalPrice,
      }, {
        headers: {
          'X-CSRF-Token': csrfToken,
          'Authorization': `Bearer ${token}`,
        },
        withCredentials: true,
      });

      setReservationStatus('Rezervacija je uspješno kreirana!');
      setAvailabilityStatus(null);
      onReservationSuccess();
    } catch (error) {
      console.error('Error creating reservation:', error);

      if (error.response && error.response.status === 401) {
        setReservationStatus('Morate biti prijavljeni kako biste izvršili rezervaciju.');
      } else {
        setReservationStatus('Rezervacija nije uspjela. Pokušajte ponovo.');
      }
    } finally {
      setLoading(false); // Postavljanje loadera na false nakon završetka procesa
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton className="bg-dark text-white">
        <Modal.Title>Rezerviraj {ad.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-light">
        <Form>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={4}>Datum početka:</Form.Label>
            <Col sm={8}>
              <DatePicker
                selected={startDate}
                onChange={handleStartDateChange}
                className="form-control"
                dateFormat="dd/MM/yyyy"
                minDate={new Date()}
                placeholderText="Izaberite datum početka"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={4}>Datum kraja:</Form.Label>
            <Col sm={8}>
              <DatePicker
                selected={endDate}
                onChange={handleEndDateChange}
                className="form-control"
                dateFormat="dd/MM/yyyy"
                minDate={startDate || new Date()}
                placeholderText="Izaberite datum kraja"
              />
            </Col>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="font-weight-bold">Ukupna cijena: <span className="text-primary">{totalPrice} KM</span></Form.Label>
          </Form.Group>
          {availabilityStatus && (
            <Alert variant={availabilityStatus.includes('dostupan') ? 'success' : 'danger'} className="text-center">
              {availabilityStatus}
            </Alert>
          )}
          {reservationStatus && (
            <Alert variant={reservationStatus.includes('uspješno') ? 'success' : 'danger'} className="text-center">
              {reservationStatus}
            </Alert>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer className="bg-dark text-white">
        <Button variant="secondary" onClick={handleClose}>
          Zatvori
        </Button>
        <Button variant="info" onClick={checkAvailability} disabled={!startDate || !endDate}>
          Provjeri raspoloživost
        </Button>
        <Button variant="primary" onClick={handleReservation} disabled={!availabilityStatus || !availabilityStatus.includes('dostupan')}>
          {loading ? <Spinner animation="border" size="sm" /> : 'Rezerviraj'} {/* Loader se prikazuje dok je loading true */}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ReservationModal;
