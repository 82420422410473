import React, { useEffect } from 'react';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import UserProfile from './UserProfile';
import AdminProfile from './AdminProfile';
import { useAuth } from './AuthContext';
import './NavigationBar.css'; // Importujemo prilagođeni CSS

const NavigationBar = () => {
  const { authState, loading } = useAuth();

  useEffect(() => {
    console.log('NavigationBar: Current authState:', authState);
  }, [authState]);

  if (loading) {
    return null; // Placeholder dok se authState učitava
  }

  return (
    <Navbar bg="dark" variant="dark" expand="lg" className="mb-4">
      <Container>
        <Link to="/" className="navbar-brand">
          Početna
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Link to="/admin/dashboard" className="nav-link">
              Administrator
            </Link>
            <Link to="/dashboard" className="nav-link">
              Upravljačka ploča
            </Link>
          </Nav>
          <Nav className="ms-auto">
            {authState?.isAuthenticated ? (
              <div className="custom-navbar-profile-icon">
                {authState.user?.role === 'administrator' ? (
                  <AdminProfile />
                ) : (
                  <UserProfile />
                )}
              </div>
            ) : (
              <>
                <Link to="/login" className="nav-link">
                  <Button variant="outline-light" className="me-2">Prijava korisnika</Button>
                </Link>
                <Link to="/admin/login" className="nav-link">
                  <Button variant="outline-light" className="me-2">Prijava administratora</Button>
                </Link>
                <Link to="/register" className="nav-link">
                  <Button variant="outline-light" className="me-2">Registracija</Button>
                </Link>
                <Link to="/contact" className="nav-link">
                  <Button variant="outline-light">Kontakt</Button>
                </Link>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavigationBar;
