import React, { useEffect, useState, useRef } from 'react';
import { Card, Container, Row, Col, Button, Carousel, Badge, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import ContactForm from './ContactForm';
import ReviewModal from './ReviewModal';
import ReservationModal from './ReservationModal.js';
import api from '../api';
import './AdsPage.css';

const AdsPage = () => {
  const [ads, setAds] = useState([]);
  const [highlightedAds, setHighlightedAds] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [adsWithoutSubcategory] = useState([]);
  const [csrfToken, setCsrfToken] = useState('');
  const [showContactModal, setShowContactModal] = useState(false);
  const [selectedAd, setSelectedAd] = useState(null);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [showReservationModal, setShowReservationModal] = useState(false);
  const [reservationSuccess, setReservationSuccess] = useState(false);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const categoryId = query.get('category');
  const subcategoryId = query.get('subcategory');
  const navigate = useNavigate();
  const videoRefs = useRef({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const csrfResponse = await api.get('/csrf-token', {
          withCredentials: true,
        });
        setCsrfToken(csrfResponse.data.csrfToken);

        const subcategoriesResponse = await api.get(`/categories/${categoryId}/subcategories`);
        setSubcategories(subcategoriesResponse.data);

        fetchAds();
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const fetchAds = async () => {
      try {
        const response = await api.get('/ads', {
          params: { category: categoryId },
        });

        const adsData = response.data.ads || response.data;

        const currentDate = new Date();
        const filteredAds = adsData.filter(ad => new Date(ad.expires_at) > currentDate);

        const highlighted = filteredAds.filter(ad => ad.isHighlighted);
        const nonHighlighted = filteredAds.filter(ad => !ad.isHighlighted);

        setHighlightedAds(highlighted);
        setAds(nonHighlighted);
      } catch (error) {
        console.error('Error fetching ads:', error);
      }
    };

    fetchData();
  }, [categoryId, subcategoryId]);

  const handleViewAds = (subcategoryId) => {
    navigate(`/ads?category=${categoryId}&subcategory=${subcategoryId}`);
  };

  const handleAdClick = async (adId) => {
    try {
      await api.post(`/ad-visits/${adId}/visit`, {}, {
        headers: {
          'X-CSRF-Token': csrfToken,
        },
        withCredentials: true,
      });
      console.log(`Recorded visit for ad with id: ${adId}`);
    } catch (error) {
      console.error('There was an error recording the ad visit!', error);
    }
  };

  const handleSlide = (selectedIndex, ad) => {
    const video = videoRefs.current[ad.id];
    if (video) {
      if (selectedIndex === ad.images.length) {
        video.play();
      } else {
        video.pause();
      }
    }
  };

  const handleContactClick = (ad) => {
    setSelectedAd(ad);
    setShowContactModal(true);
  };

  const handleReviewClick = (ad) => {
    setSelectedAd(ad);
    setShowReviewModal(true);
  };

  const handleReservationClick = (ad) => {
    setSelectedAd(ad);
    setShowReservationModal(true);
  };

  const handleCloseModal = () => {
    setShowContactModal(false);
    setSelectedAd(null);
  };

  const handleCloseReviewModal = () => {
    setShowReviewModal(false);
    setSelectedAd(null);
  };

  const handleCloseReservationModal = () => {
    setShowReservationModal(false);
    setSelectedAd(null);
    setReservationSuccess(false);
  };

  const handleReservationSuccess = () => {
    setReservationSuccess(true);
  };

  const renderAdCard = (ad, isHighlighted = false) => {
    const primaryImageIndex = ad.images.findIndex(image => image.is_primary);

    const imagesToShow = primaryImageIndex !== -1
      ? [ad.images[primaryImageIndex], ...ad.images.filter((_, index) => index !== primaryImageIndex)]
      : ad.images;

    const features = [
      ad.sea_view && 'Pogled na more',
      ad.mountain_view && 'Pogled na planine',
      ad.city_view && 'Pogled na grad',
      ad.near_restaurant && 'Blizina restorana',
      ad.near_park && 'Blizina parka',
      ad.near_beach && 'Blizina plaže',
      ad.has_wifi && 'Wi-Fi',
      ad.has_parking && 'Parking',
      ad.has_pool && 'Bazen',
      ad.has_gym && 'Teretana',
      ad.has_gps && 'GPS',
      ad.has_child_seat && 'Sjedište za dijete',
      ad.has_insurance && 'Osiguranje',
      ad.has_driver && 'Vozač',
      ad.hunting_type && `Tip Lovstva: ${ad.hunting_type}`,
      ad.has_hunting_guides && 'Lovci vodiči',
      ad.has_hunting_equipment && 'Oprema za lov',
      ad.has_lodging && 'Smještaj',
    ].filter(Boolean);

    return (
      <Col key={ad.id} xs={12} md={6} lg={4} className="mb-4">
        <Card className={`h-100 shadow-sm fixed-card-height ${isHighlighted ? 'highlighted-ad' : ''}`} onClick={() => handleAdClick(ad.id)}>
          {isHighlighted && (
            <Badge className="highlight-badge" bg="warning" text="dark">
              Istaknuti oglas
            </Badge>
          )}
          {imagesToShow && imagesToShow.length > 0 ? (
            <Carousel interval={null} onSelect={(selectedIndex) => handleSlide(selectedIndex, ad)}>
              {imagesToShow.map((image, index) => (
                <Carousel.Item key={index}>
                  <img
                    className="d-block w-100 fixed-image-size"
                    src={`${process.env.REACT_APP_BASE_URL}/storage/photos/original/${image.url}`} //za produkciju
                    //src={`${process.env.REACT_APP_BASE_URL}/photos/original/${image.url}`} za lokalnu upotrebu
                    alt={ad.title}
                    onError={(e) => {
                      console.error(`Error loading image for ad ${ad.title}:`, e.target.src);
                      e.target.src = `https://via.placeholder.com/300x200?text=No+Image`;
                    }}
                  />

                </Carousel.Item>
              ))}
              {ad.videoUrl && (
                <Carousel.Item>
                  <video
                    className="d-block w-100 fixed-image-size"
                    ref={(el) => (videoRefs.current[ad.id] = el)}
                    src={`${process.env.REACT_APP_BASE_URL}/video/${ad.videoUrl}`}
                    controls
                  />
                </Carousel.Item>
              )}
            </Carousel>
          ) : (
            <Card.Img
              variant="top"
              className="fixed-image-size"
              src={`https://via.placeholder.com/300x200?text=No+Image`}
              alt={ad.title}
              onError={(e) => {
                console.error(`Error loading image for ad ${ad.title}:`, e);
                e.target.src = `https://via.placeholder.com/300x200?text=No+Image`;
              }}
            />
          )}
          <Card.Body>
            <Card.Title>{ad.title}</Card.Title>
            <Card.Text className="ad-description">
              <strong>Opis:</strong> {ad.description}
              <br />
              <strong>Cijena:</strong> {ad.price} KM
              <br />
              {ad.city && (
                <>
                  <strong>Grad:</strong> {ad.city}
                  <br />
                </>
              )}
              {ad.address && (
                <>
                  <strong>Adresa:</strong> {ad.address}
                  <br />
                </>
              )}
              {ad.number_of_rooms > 0 && (
                <>
                  <strong>Broj soba:</strong> {ad.number_of_rooms}
                  <br />
                </>
              )}
              {ad.number_of_bathrooms > 0 && (
                <>
                  <strong>Broj kupatila:</strong> {ad.number_of_bathrooms}
                  <br />
                </>
              )}
              {features.length > 0 && (
                <ul>
                  {features.map((feature, index) => (
                    <li key={index}>{feature}</li>
                  ))}
                </ul>
              )}
            </Card.Text>

          </Card.Body>
          <Card.Footer className="card-footer">
            <div className="button-group">
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id={`tooltip-contact-${ad.id}`}>Direktni kontakt moguć samo za Premium korisnike</Tooltip>}
              >
                <Button
                  variant="secondary"
                  className="contact-owner-button"
                  onClick={() => handleContactClick(ad)}
                >
                  Kontakt
                </Button>
              </OverlayTrigger>
              <Button
                variant="success"
                className="reservation-button"
                onClick={() => handleReservationClick(ad)}
              >
                Rezerviši
              </Button>
              <Button
                variant="primary"
                className="review-button"
                onClick={() => handleReviewClick(ad)}
              >
                Recenzija
              </Button>
            </div>
          </Card.Footer>
        </Card>
      </Col>
    );
  };

  return (
    <Container className="mt-4">
      {subcategories.length > 0 && !subcategoryId ? (
        <>
          <h2>Podkategorije</h2>
          <Row>
            {subcategories.map((subcategory) => {
              const imageUrl = subcategory.imageUrl
                ? `${process.env.REACT_APP_BASE_URL}/slike_podkategorije/${subcategory.imageUrl}`
                : `https://via.placeholder.com/300x200?text=${subcategory.name}`;
              return (
                <Col key={subcategory.id} xs={12} md={6} lg={4} className="mb-4">
                  <Card className="h-100 shadow-sm">
                    <Card.Img
                      variant="top"
                      src={imageUrl}
                      alt={subcategory.name}
                      className="fixed-image-size"
                      onError={(e) => {
                        console.error(`Error loading image for subcategory ${subcategory.name}:`, e);
                        e.target.src = `https://via.placeholder.com/300x200?text=${subcategory.name}`;
                      }}
                      onClick={() => handleViewAds(subcategory.id)}
                    />
                    <Card.Body>
                      <Card.Title>{subcategory.name}</Card.Title>
                      <Card.Text>{subcategory.description}</Card.Text>
                    </Card.Body>
                    <Card.Footer className="text-center">
                      <Button variant="primary" onClick={() => handleViewAds(subcategory.id)}>
                        Pregledaj oglas
                      </Button>
                    </Card.Footer>
                  </Card>
                </Col>
              );
            })}
          </Row>
          {adsWithoutSubcategory.length > 0 && (
            <>
              <h2>Oglasi bez podkategorije</h2>
              <Row>
                {adsWithoutSubcategory.map((ad) => renderAdCard(ad))}
              </Row>
            </>
          )}
        </>
      ) : (
        <>
          {highlightedAds.length > 0 && (
            <>
              <h2>Istaknuti oglasi</h2>
              <Row>
                {highlightedAds.map((ad) => renderAdCard(ad, true))}
              </Row>
            </>
          )}
          <h2>Oglasi</h2>
          <Row>
            {ads.map((ad) => renderAdCard(ad))}
          </Row>
        </>
      )}

      <Modal
        className="custom-modal"
        show={showContactModal}
        onHide={handleCloseModal}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Kontakt</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedAd && <ContactForm adId={selectedAd.id} adOwnerEmail={selectedAd.user.email} />}
        </Modal.Body>
      </Modal>

      {selectedAd && (
        <ReviewModal
          adId={selectedAd.id}
          show={showReviewModal}
          handleClose={handleCloseReviewModal}
        />
      )}

      {selectedAd && (
        <ReservationModal
          ad={selectedAd}
          show={showReservationModal}
          handleClose={handleCloseReservationModal}
          onReservationSuccess={handleReservationSuccess} // Dodajemo callback za uspješnu rezervaciju
        />
      )}

      {reservationSuccess && selectedAd && (
        <Modal
          className="custom-modal"
          show={reservationSuccess}
          onHide={handleCloseReservationModal}
          centered
        >
          <Modal.Header closeButton className="bg-success text-white">
            <Modal.Title className="text-center w-100">Rezervacija uspješna</Modal.Title>
          </Modal.Header>
          <Modal.Body className="bg-light text-center">
            <p className="lead">
              Hvala vam na rezervaciji! <br />
              Obavezno prije putovanja stupite u kontakt sa vlasnikom oglasa putem email adrese <span className="text-primary font-weight-bold">{selectedAd.user.email}</span>.
            </p>
          </Modal.Body>
          <Modal.Footer className="bg-success text-white justify-content-center">
            <Button variant="outline-light" onClick={handleCloseReservationModal}>
              Zatvori
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Container>
  );
};

export default AdsPage;
